
// icons
import { thumbsUpOutline, thumbsDownOutline, ban, } from 'ionicons/icons';

// components
import { IonItem, IonLabel, IonChip, IonIcon, IonThumbnail, IonAvatar, } from '@ionic/vue';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  props: [
    "post",
    "hideUserChip",
    "extraRouterParams",
  ],
  components: { IonItem, IonLabel, IonChip, IonIcon, IonThumbnail, IonAvatar, },
  setup() {
    const { t } = useI18n();
    const { formatDate, isPostAuthorBanned } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      thumbsUpOutline, thumbsDownOutline, ban,

      // methods
      formatDate, isPostAuthorBanned,
    }
  }
}
