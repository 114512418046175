
// Vue reactivity
import { computed } from 'vue';

// icons
import { thumbsUpOutline, thumbsDownOutline } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonSpinner, IonList, IonButtons, IonBackButton, } from '@ionic/vue';

import PostItem from "@/components/templates/PostItem.vue";

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'UserPostListPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonSpinner, IonList, IonButtons, IonBackButton, PostItem, },
  setup() {
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingPosts);
    const posts = computed(() => store.getters.userPosts);

    // methods or filters
    const { t } = useI18n();

    // 3. return variables & methods to be used in template HTML
    return {
      t,
      
      // icons
      thumbsUpOutline, thumbsDownOutline,

      // variables
      loading, posts,
    }
  }
}
